<template>
  <div>
    <p class="text-2xl mb-6">Tasas dia</p>
    <ReporteTasasDias :filtrosShow="true" :exportar="['EXCEL']"></ReporteTasasDias>
  </div>


</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'

// demos
import ReporteTasasDias from './componentes/ReporteTasasDias.vue';
export default {
  components: {
    ReporteTasasDias
  },
  setup() {


    return {}
  },
}
</script>
